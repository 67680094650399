<template>
    <div class="shadow-lg rounded-lg bg-white">
        <div class="p-5">
            <ul class="border-b mb-2">
                <li
                    v-for="item in statusMenu"
                    v-show="item.show"
                    :key="item.status"
                    class="rounded-lg cursor-pointer w-full block p-3"
                    :class="
                        item.status == $route.params.status
                            ? 'bg-red-500 bg-opacity-20 rounded-lg'
                            : ''
                    "
                    @click="changeStatus(item.status)"
                >
                    {{ item.txt }}
                </li>
            </ul>
            <ul>
                <li
                    v-for="item in rolsMenu"
                    v-show="item.show"
                    :key="item.status"
                    class="rounded-lg cursor-pointer w-full block p-3"
                    :class="
                        item.status == $route.params.status
                            ? 'bg-red-500 bg-opacity-20 rounded-lg'
                            : ''
                    "
                    @click="changeStatus(item.status)"
                >
                    {{ item.txt }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState("permissionStore", ["isAdmin"]),
        routers() {
            return this.$route.meta.params.sideItems
                ? this.$route.meta.params.sideItems
                : [];
        },
        // 服務商上架審核狀態
        statusMenu() {
            return [
                {
                    txt: "等待審核",
                    status: "0",
                    show: this.checkPermission("provider_authentication_list"),
                },
                {
                    txt: "通過審核紀錄",
                    status: "1",
                    show: this.checkPermission("provider_authentication_list"),
                },
                {
                    txt: "申請資料不完整",
                    status: "-2",
                    show: this.checkPermission("provider_authentication_list"),
                },
                {
                    txt: "未通過審核紀錄",
                    status: "-1",
                    show: this.checkPermission("provider_authentication_list"),
                },
            ];
        },
        // 未審核申請上架服務商狀態
        rolsMenu() {
            return [
                {
                    txt: "資料填寫中",
                    status: "4",
                    show: this.checkPermission("provider_authentication_list"),
                },
                {
                    txt: "申請上架",
                    status: "3",
                    show: this.checkPermission("provider_authentication_list"),
                },
            ];
        },
    },
    methods: {
        checkPermission(key) {
            if (this.isAdmin) {
                return true;
            }
            if (this.routers.includes(key)) {
                return true;
            }
            return false;
        },
        changeStatus(status) {
            this.$router
                .replace({
                    name: "provider_authentication_list",
                    params: { status },
                    query: { time: this.$moment().valueOf() },
                })
                .catch((err) => err);
            this.$emit("onChangeStatus", { status });
        },
    },
};
</script>
