<template>
    <div class="grid grid-cols-12 gap-4">
        <div class="col-span-3">
            <Sidebar @onChangeStatus="getLists" />
        </div>

        <div class="col-span-9 rounded-lg shadow-lg bg-white p-5">
            <SearchForm @onReset="resetData" @onSearch="filterSearch" />
            <div
                v-loading="loading"
                element-loading-text="Loading.."
                element-loading-spinner="el-icon-loading"
                class="pt-3 pb-5 mt-10 relative"
            >
                <div v-if="!loading" class="overflow-x-auto">
                    <table class="myTable">
                        <thead>
                            <tr>
                                <th>暱稱</th>
                                <th>真實姓名</th>
                                <th>手機</th>
                                <th>經紀代碼</th>
                                <th>申請日期</th>
                                <th
                                    v-if="
                                        providerApplyStatus.includes(
                                            $route.params.status
                                        )
                                    "
                                >
                                    預約視訊時間
                                </th>
                                <th v-permission="['update']">編輯</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in datas"
                                :key="index"
                                class="text-center duration-200 transition"
                            >
                                <td
                                    v-if="
                                        providerApplyStatus.includes(
                                            $route.params.status
                                        )
                                    "
                                >
                                    {{ $subString(item.user.name, 10) }}
                                </td>
                                <td v-else>{{ $subString(item.name, 10) }}</td>
                                <td
                                    v-if="
                                        providerApplyStatus.includes(
                                            $route.params.status
                                        )
                                    "
                                >
                                    {{
                                        !$isEmpty(item.user.real_name)
                                            ? $subString(
                                                  item.user.real_name,
                                                  10
                                              )
                                            : item.user.real_name
                                    }}
                                </td>
                                <td v-else>
                                    {{
                                        !$isEmpty(item.real_name)
                                            ? $subString(item.real_name, 10)
                                            : item.real_name
                                    }}
                                </td>
                                <td
                                    v-if="
                                        providerApplyStatus.includes(
                                            $route.params.status
                                        )
                                    "
                                >
                                    {{ item.user.phone }}
                                </td>
                                <td v-else>
                                    {{ item.phone }}
                                </td>
                                <td
                                    v-if="
                                        providerApplyStatus.includes(
                                            $route.params.status
                                        )
                                    "
                                >
                                    {{
                                        !$isEmpty(item.user.broker)
                                            ? item.user.broker.login
                                            : null
                                    }}
                                </td>
                                <td v-else>
                                    {{
                                        !$isEmpty(item.broker)
                                            ? item.broker.login
                                            : null
                                    }}
                                </td>

                                <td
                                    v-if="
                                        providerApplyStatus.includes(
                                            $route.params.status
                                        )
                                    "
                                >
                                    {{ item.created_at | formatDate }}
                                </td>
                                <td v-else>
                                    {{ item.updated_at | formatDate }}
                                </td>
                                <td
                                    v-if="
                                        providerApplyStatus.includes(
                                            $route.params.status
                                        )
                                    "
                                >
                                    {{
                                        !$isEmpty(item.attachment.interview)
                                            ? $moment(
                                                  item.attachment.interview.date
                                              ).format("YYYY/MM/DD") +
                                              " " +
                                              item.attachment.interview.time
                                            : null
                                    }}
                                </td>
                                <td
                                    v-permission="['update']"
                                    class="cursor-pointer"
                                    @click="goToEdit(item.id)"
                                >
                                    <i class="far fa-edit"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="justify-center mt-10 flex">
                    <MyPagination
                        :paginationData="paginationData"
                        :currentPage="currentPage"
                        @onPageSizeChange="pageSizeChange"
                        @onCurrentPageChange="currentPageChange"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// 搜尋表單
import SearchForm from "./SearchForm.vue";
// 分頁組件
import MyPagination from "@/components/MyPagination.vue";
// 旋轉動畫組件
// import LoadingComponent from "@/components/Loading.vue";
import Sidebar from "./components/Sidebar.vue";
import { filter } from "lodash";
export default {
    name: "ProviderAuthenticationList",
    components: {
        Sidebar,
        SearchForm,
        MyPagination,
        // LoadingComponent,
    },
    filters: {
        showStatusText(val) {
            switch (val) {
                case 0:
                    return "等待審核";
                case 1:
                    return "通過審核";
                case -1:
                    return "未通過審核";
                default:
                    return val;
            }
        },
    },
    data() {
        return {
            // 預設頁面
            currentPage: 1,
            // 分頁資料
            paginationData: {
                limit: 15,
                total: 0,
            },
            // 搜尋條件
            filterData: {},
            // 資料
            datas: [],
            // 旋轉動畫
            loading: false,
            // 服務商審核狀態 status
            providerApplyStatus: ["0", "1", "-1", "-2"],
        };
    },
    methods: {
        /**
         * 搜尋
         * @param { tpye Object(物件) } filterData 搜尋過濾資料
         */
        filterSearch(filterData) {
            this.filterData = filterData;
            this.getLists({
                ...this.filterData,
                page: 1,
                limit: this.paginationData.limit,
            });
        },
        /**
         * 更新一頁取得幾筆資料
         * @param { type Number(數字) } val 一頁取得幾筆資料設定值
         */
        pageSizeChange(val) {
            // 更新每頁取得幾筆資料參數
            this.paginationData.limit = val;
            // 當前頁面設定為第一頁
            this.currentPage = 1;
            // 清空列表資料
            this.datas = [];
            this.getLists({
                page: this.currentPage,
                limit: this.paginationData.limit,
                ...this.filterData,
                status: this.$route.params.status,
            });
        },
        /**
         * 切換分頁
         * @param { type Number(數字) } val 分頁頁碼
         */
        currentPageChange(val) {
            // 設定目前分頁為第幾幾面
            this.currentPage = val;
            // 清空列表資料
            this.datas = [];
            this.getLists({
                page: this.currentPage,
                limit: this.paginationData.limit,
                ...this.filterData,
                status: this.$route.params.status,
            });
        },
        /**
         * 取得服務商審核狀態列表資料
         * @param { type Object(物件) } filterData 搜尋條件
         */
        async getLists(filterData) {
            this.loading = true;
            setTimeout(async () => {
                try {
                    if (
                        this.providerApplyStatus.includes(
                            this.$route.params.status
                        )
                    ) {
                        var { data } =
                            await this.$api.GetApplyToProviderListByPhpApi(
                                filterData
                            );
                    } else {
                        delete filterData.status;
                        filterData.roles = [this.$route.params.status];
                        var { data } =
                            await this.$api.GetUnReadyApplyToProviderListByApi(
                                filterData
                            );
                    }
                    this.loading = false;
                    this.currentPage = data.current_page;
                    this.paginationData = {
                        limit: data.per_page,
                        total: data.total,
                    };
                    this.datas = data.data;
                } catch (err) {
                    this.loading = false;
                    this.$message({
                        type: "error",
                        message: "取得資料失敗",
                    });
                }
            }, 500);
        },
        // 清空選擇條件
        resetData() {
            this.currentPage = 1;
            this.filterData = {};
            this.getLists({
                limit: this.paginationData.limit,
                page: 1,
                status: this.$route.params.status,
                ...this.filterData,
            });
        },
        /**
         * 跳轉編輯頁
         * @param { type String(字串) } id
         */
        goToEdit(id) {
            if (this.providerApplyStatus.includes(this.$route.params.status)) {
                this.$router.push({
                    name: "provider_authentication",
                    params: { id },
                });
            } else {
                this.$router.push({
                    name: "provider_add",
                    params: { id },
                });
            }
        },
    },

    async mounted() {
        this.$nextTick(async () => {
            await this.getLists({
                limit: this.paginationData.limit,
                ...this.filterData,
                status: this.$route.params.status,
            });
        });
    },
};
</script>
